import React from 'react'
import './Landingpage.css';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
export default function container() {





return(
    <>
    <Navbar title = "Rapid Invoice" aboutText = "About us"/>
    <div className='contain'>
        <div className='col1'><h3 id='head1' >E-Invoice Smarter, Faster, and Better</h3><Link   to="./Login"><button type="button" className="btn btn-primary" id='getstartbtn'>Get Started</button></Link> </div>
        <div className='col2'><img src="/imagefirst.png"></img></div>
        </div>
<div className='cards'>
        <div className="row row-cols-1 row-cols-md-2 g-4">
  <div className="col">
    <div className="card">
      <img src="/easy.png" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title">Easy to use</h5>
        <p className="card-text">Our user-friendly interface makes it simple for anyone to create high-quality content, even if you're not a professional writer.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card">
      <img src="/time.png" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title">Time-saving</h5>
        <p className="card-text"> Say goodbye to hours spent brainstorming and writing. Our tool allows you to generate content quickly and efficiently.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card">
      <img src="/music-controller.png" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title">Customizable</h5>
        <p className="card-text">You have complete control over the tone, style, and length of your content. Choose from a variety of writing styles and adjust the tone to match your brand's voice.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card">
      <img src="/money.png" className="card-img-top" alt="..."/>
      <div className="card-body" id='lastcard'>
        <h5 className="card-title">Affordable</h5>
        <p className="card-text">Our copywriting tool is affordable and offers excellent value for the money.</p>
      </div>
    </div>
  </div>
</div>
</div>

<div className='accord'>

<h1 id='heading2'>What you can do?</h1>

<div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button className="accordion-button collapsed" id='accordionbut' type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      Marketing
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">AI-powered copywriting tools can help generate compelling and persuasive marketing copy for advertisements, email campaigns, landing pages, and more.</div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingTwo">
      <button className="accordion-button collapsed" id='accordionbut' type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      Social media
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">AI-powered copywriting tools can help create engaging social media posts and captions that grab users' attention and drive engagement.</div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingThree">
      <button className="accordion-button collapsed" id='accordionbut' type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      Content creation
      </button>
    </h2>
    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">AI-powered copywriting tools can help generate blog post ideas, headlines, and even full articles based on your input and target audience.</div>
    </div>
  </div>
</div>
</div>

<div className='callToAction'>
    <div className='hl1'></div>
    <h1 className='heading3' id='heading2'>Take your writing to the next level!</h1>
    <h4 className='subhead1'>Join the AI Writing Revolution: Elevate Your Content with Our Cutting-Edge Tool</h4>
    <button className='callToButton'>Get Started for Free</button>
</div>

<footer>
  <div className="container">
    <div className="row">
        <p className='foot'>&copy; 2023 My Company. All rights reserved.</p>
    </div>
  </div>
</footer>


</>
)
    
}